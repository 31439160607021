import React from "react";
import "./pageStyles.css";

function healthInsurance() {
  return (
    <div className="pageContent">
      <h1>Health Insurance</h1>
      <p>
        A health insurance policy is a contract between the insurer and
        policyholder in which insurance company provides financial coverage for
        medical expenses incurred by the insured. A health policy provides
        benefit of reimbursement of medical expenses or cashless treatment
        mentioned in the health policy.
      </p>
    </div>
  );
}

export default healthInsurance;
