import React from "react";
import "./pageStyles.css";

function lifeInsurance() {
  return (
    <div className="pageContent">
      <h1>Life Insurance</h1>
      <p>
        Life Insurance is a financial product, wherein the policy holder and the
        life insurance company comes to an agreement. A lump sum amount is paid
        by the insurnace company in the form of insurance coverage to the
        nominee/insured in return for the premium after a specific period or in
        case of the death of the insured.{" "}
      </p>
    </div>
  );
}

export default lifeInsurance;
