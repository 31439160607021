import React, { Component } from 'react';
import { Image } from 'react-bootstrap';


export default class MgAdvantage extends Component {
    render() {
        return (
            <div class="mid-row">
                <div class="container">
                    <h2>MG Advantage
        <span></span>
                    </h2>
                    <p class="text-header">When you buy insurance from us, you get more than just financial safety.
        You also get: our promise of simplifying complex insurance terms and conditions, quick stress-free claims, instant quotes from top insurers and being present for you in the toughest of times.</p>

                    <a href="javascript:void(0);" class="know knowmoreSeo">Know more</a>
                    <div class="list">
                        <ul>
                            <li>
                                <span class="sprite-image lowest-price"></span>
                                <div>
                                    <p class="heading">Lowest Price</p>
                                    <p class="text">Guaranteed</p>
                                </div>
                            </li>
                            <li>
                                <span class="sprite-image unbiased-advice"></span>
                                <div>
                                    <p class="heading">Unbiased Advice</p>
                                    <p class="text">Keeping customers first</p>
                                </div>
                            </li>
                            <li>
                                <span class="sprite-image reliable"></span>
                                <div>
                                    <p class="heading">100% Reliable</p>
                                    <p class="text">Regulated by IRDAI</p>
                                </div>
                            </li>
                            <li>
                                <span class="sprite-image claim-support"></span>
                                <div>
                                    <p class="heading">Claims Support</p>
                                    <p class="text">Made stress-free</p>
                                </div>
                            </li>
                            <li>
                                <span class="sprite-image happy-help"></span>
                                <div>
                                    <p class="heading">Happy to Help</p>
                                    <p class="text">Every day of the week</p>
                                </div></li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}